<template>
    <layout :classes="classes" :routeName="routeName">
        <div class="row">
            <div class="content">
                <div class="form-container">
                    <div class="title-container">
                        <h3 class="heading small">{{$t('authentication.resetPassword.title')}}</h3>
                    </div>
                    <div class="form login">
                        <div class="form-content">
                            <div class="input-container">
                                <label :class="error('password')">{{$t('authentication.resetPassword.new')}}</label>
                                
                                <div class="password-container">
                                    <input :class="error('password')" v-model="data.password" :type="pwd_field_type" :placeholder="$t('login.form.password.placeholder')" @keyup.enter="resetPassword()">
                                    <span class="show-password cta cta-form dark" @click="change_password_field_type()"><font-awesome-icon :icon="['fa', pwd_field_type === 'password' ? 'eye' : 'eye-slash']" /></span>
                                </div>
                                
                                <FormError :data="validation.password" />
                            </div>

                            <button class="cta cta-primary submit" :class="{ loading: loading }" @click="resetPassword()">{{$t('authentication.resetPassword.label')}}</button>
                        </div>
                    </div>

                    <p class="back-to-login"><router-link class="cta cta-link light" to="/dashboard">{{$t('navigation.back')}}</router-link></p>
                </div>
            </div>

            <div class="lb-bg"></div>

            <img v-if="$screen.width <= 768" class="bottom-bg mobile" src="../../../assets/images/backgrounds/bg_auth_mobile.svg" alt="abstract background">
            <img v-else class="bottom-bg desktop" src="../../../assets/images/backgrounds/bg_auth_desktop.svg" alt="abstract background">
        </div>
    </layout>
</template>

<script>
import Layout from '../../../components/Layout.vue';
import FormError from '../../../components/Utils/FormError.vue'

export default {
    name: "ResetPassword",

    components: {
        Layout,
        FormError
    },

    props: {
        token: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            routeName: "reset-password",

            classes: {
                login: true
            },

            data: {
                password: '',
            },

            validation: {
                password: {
                    error: false,
                    key: 'errors.validation.passwordInvalid'
                }
            },

            pwd_field_type: 'password',

            formValidated: false,
            loading: false
        }
    },

    methods: {
        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false

                if(!self.data[val] )
                    self.validation[val].error = true;
            });

            if(self.data.password.length < 10 )
                self.validation.password.error = true;

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });
        },

        resetPassword: function() {
            const self = this;
            
            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;

                const data = {
                    password: self.data.password,
                    token: self.token
                };

                self.$axios
                    .post('/password/reset', data)
                    .then(response => {
                        self.loading = false;

                        if(response.status == 200){
                            self.$utils._navigate_to_name('login');
                        }
                    })
                    .catch(() => {
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        change_password_field_type: function() {
            const self = this;
            self.pwd_field_type = self.pwd_field_type === 'password' ? 'text' : 'password'
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    },

    mounted() {
        const self = this;

        if(!self.token) {
            self.$utils._navigate_to_name('login');
        }
    }
};
</script>